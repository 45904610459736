@font-face {
    font-family : 'Pristina';
    font-style  : normal;
    font-weight : 400;
    src         : local('Pristina'), url('./scss/PRISTINA.TTF') format('opentype');
}

:root {
    --theme_color      : darkred;
    --white            : #FFFFFF;
    --black            : #000000;
    --grey30           : #4D4D4D;
    --creamywhite      : #E4DAC7;
    --angular_color    : #B52E31;
    --node_color       : #68A063;
    --php_color        : #8993BE;
    --wordpress_color  : #21759B;
    --html_color       : #F16529;
    --css_color        : #2F7110;
    --react_color      : #61DBFB;
    --ui_design_color  : #0769AD;
    --java_color       : #F80102;
    --javascript_color : #FF8A27;

    --font_size        : 1.25rem;
}

.App {
    scroll-behavior         : smooth;
    font-family             : -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing  : antialiased;
    -moz-osx-font-smoothing : grayscale;
    background-color        : var(--creamywhite);
    /*font-size               : 1rem;*/
    /*margin                  : 0;*/
}

/* Define the site header color*/
.site-header {
    /*background-color : var(--creamywhite) !important;*/
    /*color            : var(--creamywhite) !important;*/
}

/* Set the logo*/
.display-tc {
    font-family : 'Pristina', sans-serif;
    font-weight : bolder;
    font-size   : 2rem !important;
    color       : var(--theme_color) !important;
}

.Section-Header {
    display        : block;
    font-size      : 2.5rem;
    font-weight    : 800;
    text-transform : uppercase;
    color          : var(--black);
    letter-spacing : 3px;
    text-align     : center;
    margin-bottom  : 2.5rem;
    position       : relative;
}

.Section-Header::after {
    content       : '';
    position      : absolute;
    top           : calc(100% + 0.5rem);
    height        : 5px;
    width         : 3rem;
    background    : var(--theme_color);
    left          : 50%;
    transform     : translateX(-50%);
    border-radius : 5px;
}

.Section-SubHeader {
    display        : block;
    font-size      : 1.75rem;
    font-weight    : 800;
    text-transform : capitalize;
    margin-bottom  : 2rem;
    color          : var(--black);
}

.Section-Body {
    text-align  : center;
    font-weight : 400;
    font-size   : var(--font_size);
    color       : var(--grey30);
}

a {
    color              : var(--theme_color) !important;
    -webkit-transition : 0.5s;
    -o-transition      : 0.5s;
    transition         : 0.5s;
}

a:hover, a:active, a:focus {
    color           : var(--theme_color);
    outline         : none;
    text-decoration : none;
}

h1, h2, h3, h4, h5, h6, figure {
    color : var(--black) !important;
}

/* Cover START */
.cover-heading-primary {
    font-size      : 3.5rem;
    text-transform : uppercase;
    letter-spacing : 3px;
    text-align     : center;
    color          : var(--black);
    font-weight    : 800;
}

@media only screen and (max-width : 37.5em) {
    .cover-heading-primary {
        font-size : 2.5rem;
    }
}

/* Cover END */

/* Card Animation to highlight on hover*/
.card {
    transition : transform 0.3s, box-shadow 0.3s;

    &:hover {
        box-shadow : 0 2rem 3rem rgba(var(--black), 0.175) !important;
        transform  : translateY(-2px);
        transition : transform 0.3s, box-shadow 0.3s;
    }
}

/* Calendly Css To remove Scroll bar on all devices*/
.calendly-overlay .calendly-popup {
    max-height : 90vh !important;
}