.SocialMedia {
  ul {
    list-style     : none;
    padding        : 0;
    margin         : 0;
    white-space    : nowrap;
    text-transform : uppercase;
    text-align     : left;
  }

  /* horizontal menu layout */
  li {
    display : inline-block;
    margin  : 0 0.5em;

    /* link style */
    a {
      -webkit-tap-highlight-color : transparent;
      color                       : var(--white);
      background                  : var(--black);
      display                     : flex;
      border-radius               : 0.3rem;
      width                       : 2.5rem;
      height                      : 2.5rem;

      &:hover {
        background : var(--theme_color);
        color      : var(--white);
      }

      svg {
        width  : 2.5rem;
        height : 2rem;
      }
    }
  }
}