@keyframes animStar {
    from {
        transform : translateY(0px);
    }
    to {
        transform : translateY(-2000px);
    }
}

@keyframes GradientBackgroundAnimation {
    0% {
        background-position : 0% 50%;
    }
    50% {
        background-position : 100% 50%;
    }
    100% {
        background-position : 0% 50%;
    }
}

@keyframes gradient {
    0% {
        background-position : 0% 0%;
    }
    50% {
        background-position : 100% 100%;
    }
    100% {
        background-position : 0% 0%;
    }
}